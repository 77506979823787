import gql from 'graphql-tag';

export const UPDATE_BOOKMARK = gql`
  mutation updateBookmark($assocId: NexusId!, $assocType: Bookmarkable!, $isDelete: Boolean) {
    updateBookmark(assocId: $assocId, assocType: $assocType, isDelete: $isDelete) {
      assocId
      assocType
      id
      insertedAt
    }
  }
`;
