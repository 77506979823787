<template>
  <div
    class="post-teaser-image"
    :style="{ paddingBottom: teaserImageAspectRatio }"
  >
    <picture v-if="responsiveImages">
      <source
        type="image/webp"
        :media="`(max-width: ${mobileOnlyWindowSize}px)`"
        :srcset="toWebp(secondaryImage)"
      >
      <source
        type="image/jpeg"
        :media="`(max-width: ${mobileOnlyWindowSize}px)`"
        :srcset="secondaryImage"
      >
      <source
        type="image/webp"
        :media="`(max-width: ${portraitTabletDownWindowSize}px)`"
        :srcset="toWebp(featuredImage)"
      >
      <source
        type="image/jpeg"
        :media="`(max-width: ${portraitTabletDownWindowSize}px)`"
        :srcset="featuredImage"
      >
      <source
        type="image/webp"
        :media="`(min-width: ${landscapeTabletUpWindowSize}px)`"
        :srcset="toWebp(secondaryImage)"
      >
      <source
        type="image/jpeg"
        :media="`(min-width: ${landscapeTabletUpWindowSize}px)`"
        :srcset="secondaryImage"
      >
      <img
        :src="fallbackImage"
        :alt="imageAlt"
        :loading="loading"
        class="post-teaser-image__image"
        data-test-id="post-teaser-image"
        :width="secondaryImageWidth"
        :height="secondaryImageHeight"
        @error="handleErrorOnImageSource"
      >
    </picture>
    <picture v-else>
      <source
        type="image/webp"
        :srcset="toWebp(teaserImageUrl)"
      >
      <source
        type="image/jpeg"
        :srcset="teaserImageUrl"
      >
      <img
        :src="teaserImageUrl"
        :alt="imageAlt"
        :loading="loading"
        class="post-teaser-image__image"
        data-test-id="post-teaser-image"
        :width="teaserImageWidth"
        :height="teaserImageHeight"
      >
    </picture>
  </div>
</template>

<script>
import { validURL, toWebp, handleErrorOnImageSource } from '@/utils/utils';

export default {
  name: 'PostTeaserImage',
  components: {},
  props: {
    post: {
      type: Object,
      required: true,
    },
    postTitle: {
      type: String,
      default: '',
    },
    responsiveImages: {
      type: Boolean,
      default: false,
    },
    featuredImageSize: {
      type: String,
      default: null,
    },
    secondaryImageSize: {
      type: String,
      default: null,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
  data() {
    return {
      mobileOnlyWindowSize: 599,
      portraitTabletDownWindowSize: 899,
      landscapeTabletUpWindowSize: 900,
    };
  },
  computed: {
    fallbackImage() {
      if (this.hasSecondaryImage) return this.secondaryImage;
      return this.featuredImage;
    },
    hasFeaturedImage() {
      return !!this.post.featuredMedia && !!this.post.featuredMedia[0];
    },
    hasSecondaryImage() {
      return !!this.post.secondaryImg && !!this.post.secondaryImg[0];
    },
    featuredImageWidth() {
      if (!this.hasFeaturedImage) { return null; }
      const mediaData = this.post.featuredMedia[0];
      return mediaData[`width${this.featuredImageSize}`] || mediaData.widthFullSize;
    },
    featuredImageHeight() {
      if (!this.hasFeaturedImage) { return null; }
      const mediaData = this.post.featuredMedia[0];
      return mediaData[`height${this.featuredImageSize}`] || mediaData.heightFullSize;
    },
    featuredImage() {
      const mediaData = this.post.featuredMedia[0];
      const specificedImagePath = mediaData[`url${this.featuredImageSize}`];

      if (specificedImagePath && validURL(specificedImagePath)) {
        return specificedImagePath;
      }

      return mediaData.url;
    },
    secondaryImageWidth() {
      if (!this.hasSecondaryImage) { return null; }
      const mediaData = this.post.secondaryImg[0];
      return mediaData[`width${this.secondaryImageSize}`] || mediaData[`width${this.featuredImageSize}`] || mediaData.widthFullSize;
    },
    secondaryImageHeight() {
      if (!this.hasSecondaryImage) { return null; }
      const mediaData = this.post.secondaryImg[0];
      return mediaData[`height${this.secondaryImageSize}`] || mediaData[`height${this.featuredImageSize}`] || mediaData.heightFullSize;
    },
    secondaryImage() {
      if (!this.hasSecondaryImage) { return null; }
      const mediaData = this.post.secondaryImg[0];

      // Size like featured if secondary isn't set. Most teasers want the same size regardless
      // of whether it's featured or secondary, but some (HomepageSlider) size them differently.
      const specificedImagePath = mediaData[`url${this.secondaryImageSize || this.featuredImageSize}`];

      if (specificedImagePath && validURL(specificedImagePath)) {
        return specificedImagePath;
      }

      const fullSizeImageSrc = mediaData.url;

      if (validURL(fullSizeImageSrc)) { return fullSizeImageSrc; }
      return '';
    },
    imageAlt() {
      if (this.hasSecondaryImage && this.hasSecondaryImage.alt) {
        return this.hasSecondaryImage.alt;
      } if (this.hasFeaturedImage && this.hasFeaturedImage.alt) {
        return this.hasFeaturedImage.alt;
      }
      return `${this.postTitle} image`;
    },
    teaserImageWidth() {
      return this.hasFeaturedImage ? this.featuredImageWidth : this.secondaryImageWidth;
    },
    teaserImageHeight() {
      return this.hasFeaturedImage ? this.featuredImageHeight : this.secondaryImageHeight;
    },
    teaserImageUrl() {
      if (this.hasFeaturedImage) {
        return this.featuredImage;
      }
      return this.secondaryImage;
    },
    teaserImageAspectRatio() {
      const hasTeaserImageAspectRatio = this.teaserImageHeight && this.teaserImageWidth;
      const hasSecondaryImageAspectRatio = this.secondaryImageHeight && this.secondaryImageWidth;
      const teaserImageAspectRatio = hasTeaserImageAspectRatio ? `${(this.teaserImageHeight / this.teaserImageWidth) * 100}%` : null;

      if (!this.responsiveImages && !hasTeaserImageAspectRatio) { return null; }
      if (!this.responsiveImages) { return teaserImageAspectRatio; }

      const secondaryImageAspectRatio = hasSecondaryImageAspectRatio ? `${(this.secondaryImageHeight / this.secondaryImageWidth) * 100}%` : null;
      const { windowWidth } = this.$root.$children[0];
      const viewportIsMobile = windowWidth < this.mobileOnlyWindowSize;
      const viewportIsTablet = windowWidth < this.portraitTabletDownWindowSize;

      if (viewportIsMobile) return secondaryImageAspectRatio;
      if (viewportIsTablet) return teaserImageAspectRatio;
      return secondaryImageAspectRatio;
    },
  },
  methods: {
    toWebp,
    handleErrorOnImageSource,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_post-teaser-image';
</style>
